html, body {
  background: #F2F4F7;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
}

h1, p {
  margin: 0;
  margin-bottom: 10px;
  margin-top: 5px;
}

.App {
  width: 100%;
  height: 100vh;
  text-align: center;
  margin: 25px auto;
  margin-bottom: 50px;
}

.App-icon {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 25px;
  max-height: 15%;
}

.container {
  max-width: 100%;
  height: 85%;
}

.terms-container {
  max-width: 65%;
  max-height: 65vh;
  min-width: 50%;
  height: 80%;
  margin: 32px auto;
  flex: 1 1;
  display: flex;
  flex-direction: inherit;
  align-items: inherit;
}

@media (max-width: 500px) {
  .container {
    overflow: hidden;
    margin: 0px auto;
  }

  .terms-container {
    min-width: 90%;
  }
}

.text-button {
  margin: 15px 5px 10px 5px;
}

.text-button-description {
  margin: 15px 5px 10px 5px;
  font-size: 14px;
}
