@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700|LLa+Belle+Aurore);
/* Fonts */
/* @import url('https://fonts.googleapis.com/icon?family=Material+Icons'); */

html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue",
    "Helvetica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f4f7;
}

textarea {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue",
    "Helvetica", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  /* font: inherit; */
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: #2459ed;
}

a:hover {
  cursor: pointer;
}

a.sub-navigation-link {
  display: block !important;
  font-size: 13px !important;
  color: rgb(32, 42, 51) !important;
  padding: 8px !important;
}

a.sub-navigation-link-active {
  color: rgb(112, 82, 196) !important;
  border-radius: 4px !important;
  background: rgb(244, 240, 255) !important;
}

.DayPicker-Day.DayPicker-Day--today {
  background: #cdedf7;
  color: white;
  font-weight: normal;
}

.DatePicker-Day--selected {
  background: #cdedf7;
  color: black;
  font-weight: normal;
}

.DayPicker-wrapper:focus {
  outline: none;
}

.DayPicker-Months:focus {
  outline: none;
}

.DayPicker-Day:focus {
  outline: none;
}

.DayPicker-Day:hover {
  cursor: pointer;
  background: #cdedf7;
}

html, body {
  background: #F2F4F7;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
}

h1, p {
  margin: 0;
  margin-bottom: 10px;
  margin-top: 5px;
}

.App {
  width: 100%;
  height: 100vh;
  text-align: center;
  margin: 25px auto;
  margin-bottom: 50px;
}

.App-icon {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 25px;
  max-height: 15%;
}

.container {
  max-width: 100%;
  height: 85%;
}

.terms-container {
  max-width: 65%;
  max-height: 65vh;
  min-width: 50%;
  height: 80%;
  margin: 32px auto;
  flex: 1 1;
  display: flex;
  flex-direction: inherit;
  align-items: inherit;
}

@media (max-width: 500px) {
  .container {
    overflow: hidden;
    margin: 0px auto;
  }

  .terms-container {
    min-width: 90%;
  }
}

.text-button {
  margin: 15px 5px 10px 5px;
}

.text-button-description {
  margin: 15px 5px 10px 5px;
  font-size: 14px;
}

